import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card-header border-0 pt-5" }
const _hoisted_2 = { class: "card-toolbar" }
const _hoisted_3 = {
  type: "button",
  class: "btn btn-sm btn-icon btn-color-primary btn-active-light-primary",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_4 = { class: "svg-icon svg-icon-2" }
const _hoisted_5 = { class: "card-body py-3" }
const _hoisted_6 = { class: "table-responsive" }
const _hoisted_7 = { class: "table align-middle gs-0 gy-4" }
const _hoisted_8 = { class: "d-flex align-items-center" }
const _hoisted_9 = { class: "symbol symbol-50px me-5" }
const _hoisted_10 = { class: "symbol-label bg-light" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "d-flex justify-content-start flex-column" }
const _hoisted_13 = {
  href: "#",
  class: "text-dark fw-bolder text-hover-primary mb-1 fs-6"
}
const _hoisted_14 = { class: "text-muted fw-bold text-muted d-block fs-7" }
const _hoisted_15 = {
  href: "#",
  class: "text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
}
const _hoisted_16 = { class: "text-muted fw-bold text-muted d-block fs-7" }
const _hoisted_17 = {
  href: "#",
  class: "text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
}
const _hoisted_18 = { class: "text-muted fw-bold text-muted d-block fs-7" }
const _hoisted_19 = {
  href: "#",
  class: "text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
}
const _hoisted_20 = { class: "text-muted fw-bold text-muted d-block fs-7" }
const _hoisted_21 = { class: "rating" }
const _hoisted_22 = { class: "text-muted fw-bold text-muted d-block fs-7 mt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Dropdown2 = _resolveComponent("Dropdown2")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.widgetClasses, "card"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "card-title align-items-start flex-column" }, [
        _createElementVNode("span", { class: "card-label fw-bolder fs-3 mb-1" }, "Member Statistics"),
        _createElementVNode("span", { class: "text-muted mt-1 fw-bold fs-7" }, "Over 500 new members")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen024.svg" })
          ])
        ]),
        _createVNode(_component_Dropdown2)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("table", _hoisted_7, [
          _cache[3] || (_cache[3] = _createElementVNode("thead", null, [
            _createElementVNode("tr", { class: "fw-bolder text-muted bg-light" }, [
              _createElementVNode("th", { class: "ps-4 min-w-300px rounded-start" }, "Agent"),
              _createElementVNode("th", { class: "min-w-125px" }, "Earnings"),
              _createElementVNode("th", { class: "min-w-125px" }, "Comission"),
              _createElementVNode("th", { class: "min-w-200px" }, "Company"),
              _createElementVNode("th", { class: "min-w-150px" }, "Rating"),
              _createElementVNode("th", { class: "min-w-200px text-end rounded-end" })
            ])
          ], -1)),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("span", _hoisted_10, [
                        _createElementVNode("img", {
                          src: item.agent.image,
                          class: "h-75 align-self-end",
                          alt: ""
                        }, null, 8, _hoisted_11)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("a", _hoisted_13, _toDisplayString(item.agent.name), 1),
                      _createElementVNode("span", _hoisted_14, _toDisplayString(item.agent.skills), 1)
                    ])
                  ])
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("a", _hoisted_15, _toDisplayString(item.earnings.value), 1),
                  _createElementVNode("span", _hoisted_16, _toDisplayString(item.earnings.remarks) + ">", 1)
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("a", _hoisted_17, _toDisplayString(item.comission.value), 1),
                  _createElementVNode("span", _hoisted_18, _toDisplayString(item.comission.remarks), 1)
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("a", _hoisted_19, _toDisplayString(item.company.name), 1),
                  _createElementVNode("span", _hoisted_20, _toDisplayString(item.company.fields), 1)
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_21, [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (i) => {
                      return _createElementVNode("div", {
                        key: i,
                        class: _normalizeClass([[item.rating.value >= i && 'checked'], "rating-label me-2"])
                      }, _cache[1] || (_cache[1] = [
                        _createElementVNode("i", { class: "bi bi-star-fill fs-5" }, null, -1)
                      ]), 2)
                    }), 64))
                  ]),
                  _createElementVNode("span", _hoisted_22, _toDisplayString(item.rating.remarks), 1)
                ]),
                _cache[2] || (_cache[2] = _createElementVNode("td", { class: "text-end" }, [
                  _createElementVNode("a", {
                    href: "#",
                    class: "btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2"
                  }, " View "),
                  _createElementVNode("a", {
                    href: "#",
                    class: "btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4"
                  }, " Edit ")
                ], -1))
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ], 2))
}