import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "card-header border-0 pt-5" }
const _hoisted_2 = { class: "card-toolbar" }
const _hoisted_3 = {
  type: "button",
  class: "btn btn-sm btn-icon btn-color-primary btn-active-light-primary",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_4 = { class: "svg-icon svg-icon-2" }
const _hoisted_5 = { class: "card-body py-3" }
const _hoisted_6 = { class: "table-responsive" }
const _hoisted_7 = { class: "table align-middle gs-0 gy-3" }
const _hoisted_8 = { class: "symbol symbol-50px me-2" }
const _hoisted_9 = {
  href: "#",
  class: "text-dark fw-bolder text-hover-primary mb-1 fs-6"
}
const _hoisted_10 = { class: "text-end text-muted fw-bold" }
const _hoisted_11 = { class: "text-end text-muted fw-bold" }
const _hoisted_12 = { class: "text-end text-dark fw-bolder fs-6 pe-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Dropdown3 = _resolveComponent("Dropdown3")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.widgetClasses, "card"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "card-title align-items-start flex-column" }, [
        _createElementVNode("span", { class: "card-label fw-bolder fs-3 mb-1" }, "Files"),
        _createElementVNode("span", { class: "text-muted mt-1 fw-bold fs-7" }, "Over 100 pending files")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen024.svg" })
          ])
        ]),
        _createVNode(_component_Dropdown3)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("table", _hoisted_7, [
          _cache[1] || (_cache[1] = _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", { class: "p-0 w-50px" }),
              _createElementVNode("th", { class: "p-0 min-w-150px" }),
              _createElementVNode("th", { class: "p-0 min-w-140px" }),
              _createElementVNode("th", { class: "p-0 min-w-120px" }),
              _createElementVNode("th", { class: "p-0 min-w-40px" })
            ])
          ], -1)),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("span", {
                      class: _normalizeClass([`bg-light-${item.color}`, "symbol-label"])
                    }, [
                      _createElementVNode("span", {
                        class: _normalizeClass([`svg-icon-${item.color}`, "svg-icon svg-icon-2x"])
                      }, [
                        _createVNode(_component_inline_svg, {
                          src: item.icon
                        }, null, 8, ["src"])
                      ], 2)
                    ], 2)
                  ])
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("a", _hoisted_9, _toDisplayString(item.title), 1)
                ]),
                _createElementVNode("td", _hoisted_10, _toDisplayString(item.technologies), 1),
                _createElementVNode("td", _hoisted_11, _toDisplayString(item.users), 1),
                _createElementVNode("td", _hoisted_12, _toDisplayString(item.size), 1)
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ], 2))
}