import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card-header border-0 pt-5" }
const _hoisted_2 = { class: "card-toolbar" }
const _hoisted_3 = {
  href: "#",
  class: "btn btn-sm btn-light-primary"
}
const _hoisted_4 = { class: "svg-icon svg-icon-2" }
const _hoisted_5 = { class: "card-body py-3" }
const _hoisted_6 = { class: "table-responsive" }
const _hoisted_7 = { class: "table align-middle gs-0 gy-4" }
const _hoisted_8 = { class: "d-flex align-items-center" }
const _hoisted_9 = { class: "symbol symbol-50px me-5" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "d-flex justify-content-start flex-column" }
const _hoisted_12 = {
  href: "#",
  class: "text-dark fw-bolder text-hover-primary mb-1 fs-6"
}
const _hoisted_13 = { class: "text-muted fw-bold text-muted d-block fs-7" }
const _hoisted_14 = {
  href: "#",
  class: "text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
}
const _hoisted_15 = {
  href: "#",
  class: "text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
}
const _hoisted_16 = {
  href: "#",
  class: "text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
}
const _hoisted_17 = { class: "text-muted fw-bold text-muted d-block fs-7" }
const _hoisted_18 = { class: "text-end" }
const _hoisted_19 = {
  href: "#",
  class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
}
const _hoisted_20 = { class: "svg-icon svg-icon-3" }
const _hoisted_21 = {
  href: "#",
  class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
}
const _hoisted_22 = { class: "svg-icon svg-icon-3" }
const _hoisted_23 = {
  href: "#",
  class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
}
const _hoisted_24 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.widgetClasses, "card"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[1] || (_cache[1] = _createElementVNode("h3", { class: "card-title align-items-start flex-column" }, [
        _createElementVNode("span", { class: "card-label fw-bolder fs-3 mb-1" }, "New Arrivals"),
        _createElementVNode("span", { class: "text-muted mt-1 fw-bold fs-7" }, "Over 500 new products")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("a", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr075.svg" })
          ]),
          _cache[0] || (_cache[0] = _createTextVNode(" New Member "))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("table", _hoisted_7, [
          _cache[4] || (_cache[4] = _createElementVNode("thead", null, [
            _createElementVNode("tr", { class: "fw-bolder text-muted bg-light" }, [
              _createElementVNode("th", { class: "ps-4 min-w-325px rounded-start" }, "Product"),
              _createElementVNode("th", { class: "min-w-125px" }, "Price"),
              _createElementVNode("th", { class: "min-w-125px" }, "Deposit"),
              _createElementVNode("th", { class: "min-w-200px" }, "Agent"),
              _createElementVNode("th", { class: "min-w-150px" }, "Status"),
              _createElementVNode("th", { class: "min-w-200px text-end rounded-end" })
            ])
          ], -1)),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("img", {
                        src: item.product.image,
                        class: "",
                        alt: ""
                      }, null, 8, _hoisted_10)
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("a", _hoisted_12, _toDisplayString(item.product.name), 1),
                      _createElementVNode("span", _hoisted_13, _toDisplayString(item.product.specs), 1)
                    ])
                  ])
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("a", _hoisted_14, _toDisplayString(item.price), 1),
                  _cache[2] || (_cache[2] = _createElementVNode("span", { class: "text-muted fw-bold text-muted d-block fs-7" }, "Paid", -1))
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("a", _hoisted_15, _toDisplayString(item.deposit), 1),
                  _cache[3] || (_cache[3] = _createElementVNode("span", { class: "text-muted fw-bold text-muted d-block fs-7" }, "Rejected", -1))
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("a", _hoisted_16, _toDisplayString(item.agent.name), 1),
                  _createElementVNode("span", _hoisted_17, _toDisplayString(item.agent.position), 1)
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("span", {
                    class: _normalizeClass(`
                      badge badge-light-${item.status.color}
                      fs-7
                      fw-bold
                    `)
                  }, _toDisplayString(item.status.label), 3)
                ]),
                _createElementVNode("td", _hoisted_18, [
                  _createElementVNode("a", _hoisted_19, [
                    _createElementVNode("span", _hoisted_20, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen019.svg" })
                    ])
                  ]),
                  _createElementVNode("a", _hoisted_21, [
                    _createElementVNode("span", _hoisted_22, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/art/art005.svg" })
                    ])
                  ]),
                  _createElementVNode("a", _hoisted_23, [
                    _createElementVNode("span", _hoisted_24, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen027.svg" })
                    ])
                  ])
                ])
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ], 2))
}