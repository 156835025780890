import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "card-body py-3" }
const _hoisted_2 = { class: "tab-content" }
const _hoisted_3 = ["id"]
const _hoisted_4 = { class: "table-responsive" }
const _hoisted_5 = { class: "table align-middle gs-0 gy-3" }
const _hoisted_6 = { class: "symbol symbol-50px" }
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  href: "#",
  class: "text-dark fw-bolder text-hover-primary mb-1 fs-6"
}
const _hoisted_9 = { class: "text-muted fw-bold d-block fs-7" }
const _hoisted_10 = { class: "rating" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.widgetClasses, "card"])
  }, [
    _cache[4] || (_cache[4] = _createStaticVNode("<div class=\"card-header border-0 pt-5\"><h3 class=\"card-title align-items-start flex-column\"><span class=\"card-label fw-bolder fs-3 mb-1\">New Members</span><span class=\"text-muted mt-1 fw-bold fs-7\">More than 400 new members</span></h3><div class=\"card-toolbar\"><ul class=\"nav\"><li class=\"nav-item\"><a class=\"nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bolder px-4 me-1\" data-bs-toggle=\"tab\" href=\"#kt_table_widget_4_tab_1\">Month</a></li><li class=\"nav-item\"><a class=\"nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1\" data-bs-toggle=\"tab\" href=\"#kt_table_widget_4_tab_2\">Week</a></li><li class=\"nav-item\"><a class=\"nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4\" data-bs-toggle=\"tab\" href=\"#kt_table_widget_4_tab_3\">Day</a></li></ul></div></div>", 1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: _normalizeClass([[index === 0 && 'show active'], "tab-pane fade"]),
            id: `kt_table_widget_4_tab_${index + 1}`
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("table", _hoisted_5, [
                _cache[3] || (_cache[3] = _createElementVNode("thead", null, [
                  _createElementVNode("tr", null, [
                    _createElementVNode("th", { class: "p-0 w-50px" }),
                    _createElementVNode("th", { class: "p-0 min-w-150px" }),
                    _createElementVNode("th", { class: "p-0 min-w-140px" }),
                    _createElementVNode("th", { class: "p-0 min-w-120px" })
                  ])
                ], -1)),
                _createElementVNode("tbody", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item, (item1, index1) => {
                    return (_openBlock(), _createElementBlock("tr", { key: index1 }, [
                      _createElementVNode("td", null, [
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("img", {
                            src: item1.image,
                            alt: ""
                          }, null, 8, _hoisted_7)
                        ])
                      ]),
                      _createElementVNode("td", null, [
                        _createElementVNode("a", _hoisted_8, _toDisplayString(item1.user.name), 1),
                        _createElementVNode("span", _hoisted_9, _toDisplayString(item1.user.position), 1)
                      ]),
                      _createElementVNode("td", null, [
                        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "text-muted fw-bold d-block fs-7" }, " Rating ", -1)),
                        _createElementVNode("div", _hoisted_10, [
                          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (i) => {
                            return _createElementVNode("div", {
                              key: i,
                              class: _normalizeClass([[item1.rating >= i && 'checked'], "rating-label me-2"])
                            }, _cache[0] || (_cache[0] = [
                              _createElementVNode("i", { class: "bi bi-star-fill fs-5" }, null, -1)
                            ]), 2)
                          }), 64))
                        ])
                      ]),
                      _cache[2] || (_cache[2] = _createElementVNode("td", { class: "text-end" }, [
                        _createElementVNode("a", {
                          href: "#",
                          class: "btn btn-icon btn-light-twitter btn-sm me-3"
                        }, [
                          _createElementVNode("i", { class: "bi bi-twitter fs-4" })
                        ]),
                        _createElementVNode("a", {
                          href: "#",
                          class: "btn btn-icon btn-light-facebook btn-sm"
                        }, [
                          _createElementVNode("i", { class: "bi bi-facebook fs-4" })
                        ])
                      ], -1))
                    ]))
                  }), 128))
                ])
              ])
            ])
          ], 10, _hoisted_3))
        }), 128))
      ])
    ])
  ], 2))
}