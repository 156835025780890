import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "card-header border-0 pt-5" }
const _hoisted_2 = { class: "card-toolbar" }
const _hoisted_3 = {
  type: "button",
  class: "btn btn-sm btn-icon btn-color-primary btn-active-light-primary",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_4 = { class: "svg-icon svg-icon-2" }
const _hoisted_5 = { class: "card-body pt-3" }
const _hoisted_6 = { class: "table-responsive" }
const _hoisted_7 = { class: "table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4" }
const _hoisted_8 = { class: "d-flex align-items-center" }
const _hoisted_9 = { class: "symbol symbol-45px me-5" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "d-flex justify-content-start flex-column" }
const _hoisted_12 = {
  href: "#",
  class: "text-dark fw-bolder text-hover-primary mb-1 fs-6"
}
const _hoisted_13 = {
  href: "#",
  class: "text-muted text-hover-primary fw-bold text-muted d-block fs-7"
}
const _hoisted_14 = { class: "text-end" }
const _hoisted_15 = {
  href: "#",
  class: "text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
}
const _hoisted_16 = { class: "text-muted fw-bold text-end" }
const _hoisted_17 = { class: "text-end" }
const _hoisted_18 = { class: "text-end" }
const _hoisted_19 = {
  href: "#",
  class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
}
const _hoisted_20 = { class: "svg-icon svg-icon-3" }
const _hoisted_21 = {
  href: "#",
  class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
}
const _hoisted_22 = { class: "svg-icon svg-icon-3" }
const _hoisted_23 = {
  href: "#",
  class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
}
const _hoisted_24 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Dropdown2 = _resolveComponent("Dropdown2")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.widgetClasses, "card"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "card-title align-items-start flex-column" }, [
        _createElementVNode("span", { class: "card-label fw-bolder fs-3 mb-1" }, "New Products"),
        _createElementVNode("span", { class: "text-muted mt-1 fw-bold fs-7" }, "Over 500 new products")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen024.svg" })
          ])
        ]),
        _createVNode(_component_Dropdown2)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("table", _hoisted_7, [
          _cache[3] || (_cache[3] = _createElementVNode("thead", null, [
            _createElementVNode("tr", { class: "border-0" }, [
              _createElementVNode("th", { class: "p-0" }),
              _createElementVNode("th", { class: "p-0 min-w-150px" }),
              _createElementVNode("th", { class: "p-0 min-w-200px" }),
              _createElementVNode("th", { class: "p-0 min-w-150px" }),
              _createElementVNode("th", { class: "p-0 min-w-100px text-end" })
            ])
          ], -1)),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      (item.user.avatar)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            alt: "Pic",
                            src: item.user.avatar
                          }, null, 8, _hoisted_10))
                        : (item.user.state)
                          ? (_openBlock(), _createElementBlock("span", {
                              key: 1,
                              class: _normalizeClass([`bg-light-${item.user.state} text-${item.user.state}`, "symbol-label fw-bolder"])
                            }, _toDisplayString(item.user.name.charAt(0)), 3))
                          : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("a", _hoisted_12, _toDisplayString(item.user.name), 1),
                      _createElementVNode("a", _hoisted_13, [
                        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "text-dark" }, "Email", -1)),
                        _createTextVNode(": " + _toDisplayString(item.user.email), 1)
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("td", _hoisted_14, [
                  _createElementVNode("a", _hoisted_15, _toDisplayString(item.price), 1),
                  _cache[2] || (_cache[2] = _createElementVNode("span", { class: "text-muted fw-bold text-muted d-block fs-7" }, "Paid", -1))
                ]),
                _createElementVNode("td", _hoisted_16, _toDisplayString(item.technologies), 1),
                _createElementVNode("td", _hoisted_17, [
                  _createElementVNode("span", {
                    class: _normalizeClass([`badge-light-${item.status.color}`, "badge"])
                  }, _toDisplayString(item.status.label), 3)
                ]),
                _createElementVNode("td", _hoisted_18, [
                  _createElementVNode("a", _hoisted_19, [
                    _createElementVNode("span", _hoisted_20, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen019.svg" })
                    ])
                  ]),
                  _createElementVNode("a", _hoisted_21, [
                    _createElementVNode("span", _hoisted_22, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/art/art005.svg" })
                    ])
                  ]),
                  _createElementVNode("a", _hoisted_23, [
                    _createElementVNode("span", _hoisted_24, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen027.svg" })
                    ])
                  ])
                ])
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ], 2))
}