import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "card-header border-0 pt-5" }
const _hoisted_2 = {
  class: "card-toolbar",
  "data-bs-toggle": "tooltip",
  "data-bs-placement": "top",
  "data-bs-trigger": "hover",
  title: "Click to add a user"
}
const _hoisted_3 = {
  href: "#",
  class: "btn btn-sm btn-light-primary",
  "data-bs-toggle": "modal",
  "data-bs-target": "#kt_modal_invite_friends"
}
const _hoisted_4 = { class: "svg-icon svg-icon-3" }
const _hoisted_5 = { class: "card-body py-3" }
const _hoisted_6 = { class: "table-responsive" }
const _hoisted_7 = { class: "table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4" }
const _hoisted_8 = { class: "fw-bolder text-muted" }
const _hoisted_9 = { class: "w-25px" }
const _hoisted_10 = { class: "form-check form-check-sm form-check-custom form-check-solid" }
const _hoisted_11 = { class: "form-check form-check-sm form-check-custom form-check-solid" }
const _hoisted_12 = ["value"]
const _hoisted_13 = { class: "d-flex align-items-center" }
const _hoisted_14 = { class: "symbol symbol-45px me-5" }
const _hoisted_15 = ["src"]
const _hoisted_16 = { class: "d-flex justify-content-start flex-column" }
const _hoisted_17 = {
  href: "#",
  class: "text-dark fw-bolder text-hover-primary fs-6"
}
const _hoisted_18 = { class: "text-muted fw-bold text-muted d-block fs-7" }
const _hoisted_19 = {
  href: "#",
  class: "text-dark fw-bolder text-hover-primary d-block fs-6"
}
const _hoisted_20 = { class: "text-muted fw-bold text-muted d-block fs-7" }
const _hoisted_21 = { class: "text-end" }
const _hoisted_22 = { class: "d-flex flex-column w-100 me-2" }
const _hoisted_23 = { class: "d-flex flex-stack mb-2" }
const _hoisted_24 = { class: "text-muted me-2 fs-7 fw-bold" }
const _hoisted_25 = { class: "progress h-6px w-100" }
const _hoisted_26 = ["aria-valuenow"]
const _hoisted_27 = { class: "text-end" }
const _hoisted_28 = {
  href: "#",
  class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
}
const _hoisted_29 = { class: "svg-icon svg-icon-3" }
const _hoisted_30 = {
  href: "#",
  class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
}
const _hoisted_31 = { class: "svg-icon svg-icon-3" }
const _hoisted_32 = {
  href: "#",
  class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
}
const _hoisted_33 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card", _ctx.widgetClasses])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[3] || (_cache[3] = _createElementVNode("h3", { class: "card-title align-items-start flex-column" }, [
        _createElementVNode("span", { class: "card-label fw-bolder fs-3 mb-1" }, "Members Statistics"),
        _createElementVNode("span", { class: "text-muted mt-1 fw-bold fs-7" }, "Over 500 members")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("a", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr075.svg" })
          ]),
          _cache[2] || (_cache[2] = _createTextVNode(" New Member "))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("table", _hoisted_7, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", _hoisted_8, [
              _createElementVNode("th", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("input", {
                    class: "form-check-input",
                    type: "checkbox",
                    onChange: _cache[0] || (_cache[0] = ($event: any) => (
                      _ctx.checkedRows =
                        _ctx.checkedRows.length === 6 ? [] : [0, 1, 2, 3, 4, 5]
                    ))
                  }, null, 32)
                ])
              ]),
              _cache[4] || (_cache[4] = _createElementVNode("th", { class: "min-w-150px" }, "Authors", -1)),
              _cache[5] || (_cache[5] = _createElementVNode("th", { class: "min-w-140px" }, "Company", -1)),
              _cache[6] || (_cache[6] = _createElementVNode("th", { class: "min-w-120px" }, "Progress", -1)),
              _cache[7] || (_cache[7] = _createElementVNode("th", { class: "min-w-100px text-end" }, "Actions", -1))
            ])
          ]),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_11, [
                    _withDirectives(_createElementVNode("input", {
                      class: "form-check-input widget-9-check",
                      type: "checkbox",
                      value: index,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.checkedRows) = $event))
                    }, null, 8, _hoisted_12), [
                      [_vModelCheckbox, _ctx.checkedRows]
                    ])
                  ])
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("img", {
                        src: item.image,
                        alt: ""
                      }, null, 8, _hoisted_15)
                    ]),
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("a", _hoisted_17, _toDisplayString(item.name), 1),
                      _createElementVNode("span", _hoisted_18, _toDisplayString(item.skills), 1)
                    ])
                  ])
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("a", _hoisted_19, _toDisplayString(item.companyName), 1),
                  _createElementVNode("span", _hoisted_20, _toDisplayString(item.companySkills), 1)
                ]),
                _createElementVNode("td", _hoisted_21, [
                  _createElementVNode("div", _hoisted_22, [
                    _createElementVNode("div", _hoisted_23, [
                      _createElementVNode("span", _hoisted_24, _toDisplayString(item.value) + "% ", 1)
                    ]),
                    _createElementVNode("div", _hoisted_25, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["progress-bar", `bg-${item.color}`]),
                        role: "progressbar",
                        style: _normalizeStyle({ width: item.value + '%' }),
                        "aria-valuenow": item.value,
                        "aria-valuemin": "0",
                        "aria-valuemax": "100"
                      }, null, 14, _hoisted_26)
                    ])
                  ])
                ]),
                _createElementVNode("td", _hoisted_27, [
                  _createElementVNode("a", _hoisted_28, [
                    _createElementVNode("span", _hoisted_29, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen019.svg" })
                    ])
                  ]),
                  _createElementVNode("a", _hoisted_30, [
                    _createElementVNode("span", _hoisted_31, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/art/art005.svg" })
                    ])
                  ]),
                  _createElementVNode("a", _hoisted_32, [
                    _createElementVNode("span", _hoisted_33, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen027.svg" })
                    ])
                  ])
                ])
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ], 2))
}