import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card-header border-0 pt-5" }
const _hoisted_2 = { class: "card-toolbar" }
const _hoisted_3 = {
  type: "button",
  class: "btn btn-sm btn-icon btn-color-primary btn-active-light-primary",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_4 = { class: "svg-icon svg-icon-2" }
const _hoisted_5 = { class: "card-body py-3" }
const _hoisted_6 = { class: "table-responsive" }
const _hoisted_7 = { class: "table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3" }
const _hoisted_8 = { class: "fw-bolder text-muted" }
const _hoisted_9 = { class: "w-25px" }
const _hoisted_10 = { class: "form-check form-check-sm form-check-custom form-check-solid" }
const _hoisted_11 = { class: "form-check form-check-sm form-check-custom form-check-solid" }
const _hoisted_12 = ["value"]
const _hoisted_13 = {
  href: "#",
  class: "text-dark fw-bolder text-hover-primary fs-6"
}
const _hoisted_14 = {
  href: "#",
  class: "text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
}
const _hoisted_15 = { class: "text-muted fw-bold text-muted d-block fs-7" }
const _hoisted_16 = {
  href: "#",
  class: "text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
}
const _hoisted_17 = { class: "text-muted fw-bold text-muted d-block fs-7" }
const _hoisted_18 = {
  href: "#",
  class: "text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
}
const _hoisted_19 = { class: "text-muted fw-bold text-muted d-block fs-7" }
const _hoisted_20 = { class: "text-dark fw-bolder text-hover-primary fs-6" }
const _hoisted_21 = { class: "text-end" }
const _hoisted_22 = {
  href: "#",
  class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
}
const _hoisted_23 = { class: "svg-icon svg-icon-3" }
const _hoisted_24 = {
  href: "#",
  class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
}
const _hoisted_25 = { class: "svg-icon svg-icon-3" }
const _hoisted_26 = {
  href: "#",
  class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
}
const _hoisted_27 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Dropdown2 = _resolveComponent("Dropdown2")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.widgetClasses, "card"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[2] || (_cache[2] = _createElementVNode("h3", { class: "card-title align-items-start flex-column" }, [
        _createElementVNode("span", { class: "card-label fw-bolder fs-3 mb-1" }, "Recent Orders"),
        _createElementVNode("span", { class: "text-muted mt-1 fw-bold fs-7" }, "Over 500 orders")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen024.svg" })
          ])
        ]),
        _createVNode(_component_Dropdown2)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("table", _hoisted_7, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", _hoisted_8, [
              _createElementVNode("th", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("input", {
                    class: "form-check-input",
                    type: "checkbox",
                    onChange: _cache[0] || (_cache[0] = ($event: any) => (
                      _ctx.checkedRows =
                        _ctx.checkedRows.length === 6 ? [] : [0, 1, 2, 3, 4, 5]
                    ))
                  }, null, 32)
                ])
              ]),
              _cache[3] || (_cache[3] = _createElementVNode("th", { class: "min-w-150px" }, "Order Id", -1)),
              _cache[4] || (_cache[4] = _createElementVNode("th", { class: "min-w-140px" }, "Country", -1)),
              _cache[5] || (_cache[5] = _createElementVNode("th", { class: "min-w-120px" }, "Date", -1)),
              _cache[6] || (_cache[6] = _createElementVNode("th", { class: "min-w-120px" }, "Company", -1)),
              _cache[7] || (_cache[7] = _createElementVNode("th", { class: "min-w-120px" }, "Total", -1)),
              _cache[8] || (_cache[8] = _createElementVNode("th", { class: "min-w-120px" }, "Status", -1)),
              _cache[9] || (_cache[9] = _createElementVNode("th", { class: "min-w-100px text-end" }, "Actions", -1))
            ])
          ]),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_11, [
                    _withDirectives(_createElementVNode("input", {
                      class: "form-check-input widget-13-check",
                      type: "checkbox",
                      value: index,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.checkedRows) = $event))
                    }, null, 8, _hoisted_12), [
                      [_vModelCheckbox, _ctx.checkedRows]
                    ])
                  ])
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("a", _hoisted_13, _toDisplayString(item.orderid), 1)
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("a", _hoisted_14, _toDisplayString(item.company.name), 1),
                  _createElementVNode("span", _hoisted_15, "Code: " + _toDisplayString(item.country.code), 1)
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("a", _hoisted_16, _toDisplayString(item.date.value), 1),
                  _createElementVNode("span", _hoisted_17, "Code: " + _toDisplayString(item.date.remarks), 1)
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("a", _hoisted_18, _toDisplayString(item.company.name), 1),
                  _createElementVNode("span", _hoisted_19, _toDisplayString(item.company.fields), 1)
                ]),
                _createElementVNode("td", _hoisted_20, _toDisplayString(item.total), 1),
                _createElementVNode("td", null, [
                  _createElementVNode("span", {
                    class: _normalizeClass([`badge-light-${item.status.color}`, "badge"])
                  }, _toDisplayString(item.status.label), 3)
                ]),
                _createElementVNode("td", _hoisted_21, [
                  _createElementVNode("a", _hoisted_22, [
                    _createElementVNode("span", _hoisted_23, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen019.svg" })
                    ])
                  ]),
                  _createElementVNode("a", _hoisted_24, [
                    _createElementVNode("span", _hoisted_25, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/art/art005.svg" })
                    ])
                  ]),
                  _createElementVNode("a", _hoisted_26, [
                    _createElementVNode("span", _hoisted_27, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen027.svg" })
                    ])
                  ])
                ])
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ], 2))
}